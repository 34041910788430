/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import { COLORS } from '@app/constants';
import FeatureItem from '@app/pages/AutorapLanding/features/FeatureItem';
import { theme, pages } from '@app/config';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 130px;
  color: ${COLORS.white};

  & > * {
    flex: 1;
  }

  ${theme.breakpoints.desktop} {
    & > *:not(:last-child) {
      margin-right: 20px;
    }
  }

  ${theme.breakpoints.tablet} {
    flex-direction: column;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 0;
    }
  }

  ${theme.breakpoints.tablet} {
    margin-top: 50px;

    & > *:not(:first-child) {
      margin-top: 80px;
    }
  }
`;

const featureItems = pages.autorap.features.map((feature, index) => (
  <FeatureItem key={index} {...feature} />
));
const FeaturesColumns: React.FC = () => <Container>{featureItems}</Container>;

export default FeaturesColumns;
