/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import { COLORS } from '@app/constants';
import { theme } from '@app/config';

const ScreenPreviewContainer = styled.div`
  flex: 1;
  max-width: 300px;
  color: ${COLORS.black};

  ${theme.breakpoints.mobile} {
    max-width: 275px;
    color: ${COLORS.white};
  }

  ${theme.breakpoints.xsmobile} {
    max-width: 250px;
  }
`;

const PreviewFrame = styled.div<any>`
  position: relative;
  padding-bottom: 210%;
  border: 2px solid #161616;
  border-radius: 33px;
  background-color: ${COLORS.black};

  ${theme.breakpoints.mobile} {
    padding-bottom: 177%;
    border: none;
  }

  &:before {
    content: '';
    position: absolute;
    top: 30px;
    right: 0;
    bottom: 30px;
    left: 0;
    background-image: ${(props) => props['1x'] && `url('${props['1x']}')`};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    ${theme.breakpoints.retina} {
      background-image: ${(props) => props['2x'] && `url('${props['2x']}')`};
    }

    ${theme.breakpoints.mobile} {
      top: 0;
      bottom: 0;
      border: 1px solid #545454;
      border-radius: 13px;
    }
  }
`;

const PreviewHeader = styled.div`
  min-height: 48px;
  margin-top: 45px;
  font-size: 21px;
  font-weight: 800;
  word-break: break-word;
  hyphens: auto;
  text-transform: uppercase;

  ${theme.breakpoints.mobile} {
    min-height: 38px;
    margin-top: 25px;
    font-size: 16px;
    line-height: 19px;
  }
`;

const PreviewDescription = styled.div`
  font-size: 18px;
  line-height: 21px;
  word-break: break-word;
  opacity: 0.8;

  ${theme.breakpoints.tablet} {
    margin-top: 15px;
  }
`;

interface OwnProps {
  '1x': string;
  '2x': string;
  header: string;
  description: string;
  'aria-label': string;
}

const ScreenPreview: React.FC<OwnProps> = (props) => {
  return (
    <ScreenPreviewContainer>
      <PreviewFrame {...props} />
      <PreviewHeader>{props.header}</PreviewHeader>
      <PreviewDescription>{props.description}</PreviewDescription>
    </ScreenPreviewContainer>
  );
};

export default ScreenPreview;
