/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import { autorapSections } from '@app/constants';
import {
  AppleStoreButton,
  GoogleStoreButton,
} from '@app/pages/AutorapLanding/shared/StoreLinkButtons';
import { theme } from '@app/config';

const StoreLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;

  & > *:not(:last-child) {
    margin-right: 45px;
  }

  ${theme.breakpoints.mobile} {
    margin-top: 40px;
  }
`;

const StoreLinks: React.FC = () => (
  <StoreLinksContainer>
    <AppleStoreButton
      sectionId={autorapSections.CONTACT}
      data-test-id="footer-btn-applestore"
    />
    <GoogleStoreButton
      sectionId={autorapSections.CONTACT}
      data-test-id="footer-btn-googlestore"
    />
  </StoreLinksContainer>
);

export default StoreLinks;
