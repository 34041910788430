/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import { AboutPreviews } from '@app/assets';
import ScreenPreview from '@app/pages/AutorapLanding/about/ScreenPreview';
import { theme, pages } from '@app/config';

const Container = styled.div`
  margin-top: 75px;

  ${theme.breakpoints.mobile} {
    margin-top: 40px;
    padding-bottom: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }
`;

const PreviewItemsContainer = styled.div`
  display: flex;
  justify-content: center;

  & > *:not(:last-child) {
    margin-right: 55px;
  }

  ${theme.breakpoints.tablet} {
    & > *:not(:last-child) {
      margin-right: 30px;
    }
  }

  ${theme.breakpoints.mobile} {
    width: 1250px;

    & > *:not(:last-child) {
      margin-right: 25px;
    }
  }

  ${theme.breakpoints.xsmobile} {
    width: 1120px;
  }
`;

const previewItems = pages.autorap.previews.map((preview, index) => (
  <ScreenPreview key={index} {...preview} {...AboutPreviews[index]} />
));

const ScreensPreview: React.FC = () => (
  <Container>
    <PreviewItemsContainer>{previewItems}</PreviewItemsContainer>
  </Container>
);

export default ScreensPreview;
