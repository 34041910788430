/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import { COLORS } from '@app/constants';
import { StoreButtons } from '@app/assets';
import {
  isOSDevice,
  isAndroidDevice,
  emitAnalyticsEvent,
} from '@app/utils/utils';
import { theme, pages } from '@app/config';

type StoreLinkButtonProps = {
  type: 'AppleStore' | 'GoogleStore';
  href: string;
  ['data-test-id']: string;
  onClick: any;
  width?: string;
  bgSize?: string;
  height?: string;
};

const StoreLinkButton = styled.a.attrs((props) => ({
  href: props.href || '#',
  'aria-label': props.type,
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  width: ${(props) => props.width || '254px'};
  min-width: ${(props) => props.bgSize || '152px'};
  height: ${(props) => props.height || '78px'};
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  background-color: ${COLORS.black};
  background-image: ${(props: StoreLinkButtonProps) =>
    props.type && `url('${StoreButtons[props.type].normal}')`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: ${(props) => props.bgSize || '152px'};
  text-decoration: none;

  ${theme.breakpoints.retina} {
    background-image: ${(props: StoreLinkButtonProps) =>
      props.type && `url('${StoreButtons[props.type].retina}')`};
  }

  ${theme.breakpoints.mobile} {
    width: 300px;
  }

  ${theme.breakpoints.xsmobile} {
    width: 260px;
  }
`;

interface OwnProps {
  sectionId: string;
  ['data-test-id']: string;
}

export const AppleStoreButton: React.FC<OwnProps> = ({
  sectionId,
  ...rest
}: OwnProps) => {
  const onClick = (): void => {
    emitAnalyticsEvent({
      eventLabel: sectionId,
      eventCategory: 'Apple',
    });
  };
  if (isAndroidDevice) {
    return null;
  }
  return (
    <StoreLinkButton
      onClick={onClick}
      type="AppleStore"
      bgSize="152px"
      href={pages.autorap.stores.apple}
      {...rest}
    />
  );
};

export const GoogleStoreButton: React.FC<OwnProps> = ({
  sectionId,
  ...rest
}: OwnProps) => {
  const onClick = (): void => {
    emitAnalyticsEvent({
      eventLabel: sectionId,
      eventCategory: 'Google',
    });
  };
  if (isOSDevice) {
    return null;
  }
  return (
    <StoreLinkButton
      onClick={onClick}
      type="GoogleStore"
      bgSize="154px"
      href={pages.autorap.stores.google}
      {...rest}
    />
  );
};
