/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import {
  FacebookButton,
  TwitterButton,
  InstagramButton,
} from '@app/pages/AutorapLanding/shared/SocialNetworkButtons';
import { theme } from '@app/config';

const SocialNetworksContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;

  & > *:not(:last-child) {
    margin-right: 100px;
  }

  ${theme.breakpoints.mobile} {
    margin-top: 10px;

    & > *:not(:last-child) {
      margin-right: 50px;
    }
  }
`;

const SocialNetworks: React.FC = () => (
  <SocialNetworksContainer>
    <FacebookButton />
    <TwitterButton />
    <InstagramButton />
  </SocialNetworksContainer>
);

export default SocialNetworks;
