/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import { FeaturesBackground } from '@app/assets';
import { COLORS, autorapSections } from '@app/constants';
import FeaturesColumns from '@app/pages/AutorapLanding/features/FeaturesColumns';
import ContentContainer from '@app/pages/AutorapLanding/shared/ContentContainer';
import SectionHeader from '@app/pages/AutorapLanding/shared/SectionHeader';
import { theme } from '@app/config';

const FeaturesContainer = styled.section`
  position: relative;
  padding: 100px 0 90px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background-color: ${COLORS.black};
  text-align: center;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('${FeaturesBackground}');
    background-position: center center;
    background-repeat: no-repeat;
    z-index: -1;
  }

  ${theme.breakpoints.mobile} {
    padding: 70px 0 40px;
  }
`;

const FeaturesHeader = styled(SectionHeader)`
  color: ${COLORS.white};
`;

const FeaturesSection: React.FC = () => (
  <FeaturesContainer id={autorapSections.FEATURES}>
    <ContentContainer>
      <FeaturesHeader>Features</FeaturesHeader>
      <FeaturesColumns />
    </ContentContainer>
  </FeaturesContainer>
);

export default FeaturesSection;
