/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import { COLORS } from '@app/constants';
import {
  isAndroidDevice,
  emitAnalyticsEvent,
  getMostVisibleSection,
} from '@app/utils/utils';
import { pages } from '@app/config';

const DownloadButtonItem = styled.a.attrs(() => ({
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 125px;
  height: 100%;
  font-size: 14px;
  font-weight: 800;
  color: ${COLORS.white};
  text-decoration: none;
  text-transform: uppercase;
  background-color: #fb0d1c;
`;

const buttonHref = pages.autorap.stores[isAndroidDevice ? 'google' : 'apple'];

const MobileDownloadButton: React.FC = () => (
  <DownloadButtonItem
    href={buttonHref}
    data-test-id="navbar-mobile-btn-download"
    onClick={() => {
      emitAnalyticsEvent({
        eventLabel: `${getMostVisibleSection()}>>download`,
      });
    }}
  >
    Download
  </DownloadButtonItem>
);

export default MobileDownloadButton;
