/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { createGlobalStyle } from 'styled-components';

export const AutorapGlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    background: #000;
    font-family: -apple-system, BlinkMacSystemFont, "Open Sans", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    color: #444;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%;
  }

  * {
    box-sizing: border-box;
  }

  ::selection {
    background: red;
  }

  ::-moz-selection {
    background: red;
  }

  #app {
    height: 100%;
  }
`;
