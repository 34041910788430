/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import { COLORS, autorapLayoutSizes } from '@app/constants';
import { emitAnalyticsEvent, getMostVisibleSection } from '@app/utils/utils';
import { theme } from '@app/config';

const NavigationContainer = styled.nav`
  display: flex;
  align-items: center;
  height: ${autorapLayoutSizes.headerHeight}px;
  transition: height 0.3s;
`;

const MainNavigationItem = styled.a`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 12px;
  color: ${COLORS.white};
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  transition: opacity 0.5s;
  will-change: opacity;

  &:hover {
    opacity: 0.8;
  }

  ${theme.breakpoints.tablet} {
    padding: 0 9px;
    font-size: 16px;
  }
`;

const DownloadNavigationItem = styled(MainNavigationItem)`
  padding: 0 22px;
  background-color: #fb0d1c;
  transition: background-color 0.5s;
  will-change: background-color;

  &:hover {
    background-color: #d70815;
    opacity: 1;
  }
`;

interface NavigationProps {
  isHeaderSticked: boolean;
}

const MainNavigation: React.FC<NavigationProps> = ({ isHeaderSticked }) => (
  <NavigationContainer>
    <MainNavigationItem href="#about" data-test-id="navbar-btn-about">
      About
    </MainNavigationItem>
    <MainNavigationItem href="#features" data-test-id="navbar-btn-features">
      Features
    </MainNavigationItem>
    <MainNavigationItem href="#contact" data-test-id="navbar-btn-contact">
      Contact
    </MainNavigationItem>
    {isHeaderSticked && (
      <DownloadNavigationItem
        href="#"
        data-test-id="navbar-btn-download"
        onClick={() => {
          emitAnalyticsEvent({
            eventLabel: `${getMostVisibleSection()}>>download`,
          });
        }}
      >
        Download
      </DownloadNavigationItem>
    )}
  </NavigationContainer>
);

export default MainNavigation;
