/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

/* eslint-disable max-len */
import React from 'react';
import { Helmet } from 'react-helmet';

import { OgImage } from '@app/assets';
import AboutSection from '@app/pages/AutorapLanding/about';
import FeaturesSection from '@app/pages/AutorapLanding/features';
import FooterSection from '@app/pages/AutorapLanding/footer';
import HeaderSection from '@app/pages/AutorapLanding/header';
import CookiesBanner from '@app/pages/AutorapLanding/shared/CookiesBanner';

import { AutorapGlobalStyle } from '@app/styles/global_autorap';

const AutoRapLanding: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={`AutoRap is the only rap app on the market that can 
          transform your speech into an epic rap song with a dope beat in a matter of seconds and start rap battles.`}
        />
        <meta
          name="keywords"
          content="Autorap, Rap, Rap app, smule, rap battle app, autorap app, rap maker app, pitch correction, rap battle"
        />
        <meta
          property="og:title"
          content="AutoRap – Rap & Hip-hop App. Record raps on your phone"
        />
        <meta
          property="og:description"
          content="The only rap app that transforms your speech into an epic rap song"
        />
        <meta property="og:image" content={OgImage} />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap"
          rel="stylesheet"
        />
        <title>
          AutoRap - Rap App - Freestyle and record rap songs on popular beats
        </title>
      </Helmet>
      <AutorapGlobalStyle />
      <HeaderSection />
      <AboutSection />
      <FeaturesSection />
      <FooterSection />
      <CookiesBanner />
    </React.Fragment>
  );
};

export default AutoRapLanding;
