/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import { COLORS } from '@app/constants';
import { MobileNavigationSmoke, MobileNavigationSmoke2x } from '@app/assets';
import MobileNavigationToggle from '@app/pages/AutorapLanding/header/MobileNavigationToggle';
import {
  emitAnalyticsEvent,
  isAndroidDevice,
  getMostVisibleSection,
} from '@app/utils/utils';
import { theme, pages } from '@app/config';

const MobileNavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: url('${MobileNavigationSmoke}') ${COLORS.black} no-repeat center
    bottom / 100%;
  z-index: 9998;

  ${theme.breakpoints.retina} {
    background-image: url('${MobileNavigationSmoke2x}');
  }

  &:after {
    content: '';
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      ${COLORS.black} 100%
    );
  }

  & > *:first-child {
    margin-top: -125px;
  }

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }

  ${theme.breakpoints.xsmobile} {
    & > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

const MobileNavigationItem = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 218px;
  height: 67px;
  font-size: 24px;
  font-weight: 800;
  line-height: 84px;
  color: ${COLORS.white};
  text-decoration: none;
  text-transform: uppercase;

  ${theme.breakpoints.xsmobile} {
    height: 50px;
    font-size: 22px;
    line-height: 75px;
  }
`;

const DownloadMobileNavigationItem = styled(MobileNavigationItem).attrs(() => ({
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  background-color: #fb0d1c;
`;

// Import this style globally in order to disable the page scroll when the navigation menu is being opened.
const disabledBodyScrollStyles = 'body {overflow: hidden}';
const downloadButtonHref =
  pages.autorap.stores[isAndroidDevice ? 'google' : 'apple'];

const MobileNavigation: React.FC = () => {
  const [isMobileNavigationOpened, toggleMobileNavigation] = useState(false);
  const closeMobileNavigation = (): void => {
    toggleMobileNavigation(false);
  };
  const onToggleNavigationButtonClick = (): void => {
    // TODO: Fire GA event ONLY when open the mobile navigation.
    if (!isMobileNavigationOpened) {
      emitAnalyticsEvent({
        eventName: 'Autorap Hamburger Toggle',
        eventCategory: 'open',
      });
    }

    toggleMobileNavigation(!isMobileNavigationOpened);
  };
  const onDownloadNavigationItemClick = (): void => {
    emitAnalyticsEvent({
      eventLabel: `${getMostVisibleSection()}>>download`,
    });
    closeMobileNavigation();
  };

  return (
    <Fragment>
      <MobileNavigationToggle
        onClick={onToggleNavigationButtonClick}
        isMobileNavigationOpened={isMobileNavigationOpened}
      />
      {isMobileNavigationOpened && (
        <MobileNavigationContainer>
          <MobileNavigationItem
            href="#about"
            data-test-id="navbox-btn-about"
            onClick={closeMobileNavigation}
          >
            About
          </MobileNavigationItem>
          <MobileNavigationItem
            href="#features"
            data-test-id="navbox-btn-features"
            onClick={closeMobileNavigation}
          >
            Features
          </MobileNavigationItem>
          <MobileNavigationItem
            href="#contact"
            data-test-id="navbox-btn-contact"
            onClick={closeMobileNavigation}
          >
            Contact
          </MobileNavigationItem>
          <DownloadMobileNavigationItem
            href={downloadButtonHref}
            data-test-id="navbox-btn-download"
            onClick={onDownloadNavigationItemClick}
          >
            Download
          </DownloadMobileNavigationItem>
          <style type="text/css">{disabledBodyScrollStyles}</style>
        </MobileNavigationContainer>
      )}
    </Fragment>
  );
};

export default MobileNavigation;
