/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import styled from 'styled-components';

import { theme } from '@app/config';

export default styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-size: 36px;
  font-weight: 800;
  line-height: 43px;
  text-transform: uppercase;

  ${theme.breakpoints.mobile} {
    font-size: 18px;
    line-height: 21px;
  }
`;
