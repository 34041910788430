/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import { useState, useLayoutEffect } from 'react';

const useStickyContainer = (scrollYOffset: number): boolean => {
  const [isSticked, setIsSticked] = useState(false);

  const handleScrollPosition = (): void => {
    setIsSticked(window.scrollY >= scrollYOffset);
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScrollPosition);

    return () => {
      window.removeEventListener('scroll', handleScrollPosition);
    };
  }, []);

  return isSticked;
};

export default useStickyContainer;
