/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import { autorapSections } from '@app/constants';
import StaticHeader from '@app/pages/AutorapLanding/header/StaticHeader';
import StickyHeader from '@app/pages/AutorapLanding/header/StickyHeader';
import useStickyContainer from '@app/pages/AutorapLanding/shared/useStickyContainer';
import { getViewportWidth } from '@app/utils/utils';
import { theme } from '@app/config';

const HeaderContainer = styled.header`
  width: 100%;
  background-color: ${theme.colors.main};

  ${theme.breakpoints.mobile} {
    height: 100%;
  }
`;

const HeaderSection: React.FC = () => {
  const isSticked = useStickyContainer(getViewportWidth() > 600 ? 100 : 60);

  return (
    <HeaderContainer id={autorapSections.HEADER}>
      <StickyHeader isSticked={isSticked} />
      <StaticHeader isSticked={isSticked} />
    </HeaderContainer>
  );
};

export default HeaderSection;
