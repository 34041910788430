/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import { COLORS } from '@app/constants';
import { isLaptop } from '@app/utils/utils';
import { theme } from '@app/config';
import { HomeAppsAutorapSvg } from '@app/assets';

const Icon = () => <SVG src={HomeAppsAutorapSvg} uniquifyIDs={true} />;

const LogoContainer = styled.a.attrs(() => ({
  href: '#',
}))`
  display: flex;
  text-decoration: none;

  ${theme.breakpoints.mobile} {
    display: none;
  }
`;

interface LogoProps {
  isHeaderSticked: boolean;
}

const LogoSVGContainer = styled.div`
  border-radius: 16px;
  overflow: hidden;
  width: ${(props: LogoProps) =>
    props.isHeaderSticked || isLaptop ? '70' : '79'}px;
  height: ${(props: LogoProps) =>
    props.isHeaderSticked || isLaptop ? '70' : '79'}px;
  & svg {
    width: 100%;
    height: auto;
  }
`;

const LogoText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;

  & > span:first-child {
    font-size: ${(props: LogoProps) => (props.isHeaderSticked ? '26' : '36')}px;
    line-height: ${(props: LogoProps) =>
      props.isHeaderSticked ? '31' : '43'}px;
    color: ${COLORS.white};
    transition: all 0.5s;
  }

  & > span:last-child {
    display: ${(props: LogoProps) => (props.isHeaderSticked ? 'none' : '')};
    font-size: 18px;
    line-height: 21px;
    color: ${COLORS.white};
    opacity: 0.4;
  }
`;

const LogoAutorap: React.FC<LogoProps> = ({ isHeaderSticked }) => (
  <LogoContainer data-test-id="header-logo">
    <LogoSVGContainer isHeaderSticked={isHeaderSticked}>
      <Icon />
    </LogoSVGContainer>
    <LogoText isHeaderSticked={isHeaderSticked}>
      <span>AutoRap</span>
      <span>by Smule</span>
    </LogoText>
  </LogoContainer>
);

export default LogoAutorap;
