/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import '@app/polyfills';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

import AutoRapLanding from './app/pages/AutorapLanding/AutoRapLanding';

const container = document.getElementById('app');
const root = createRoot(container!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

root.render(<AutoRapLanding />);
