/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import { COLORS, autorapLayoutSizes } from '@app/constants';
import LogoAutorap from '@app/pages/AutorapLanding/header/LogoAutorap';
import MainNavigation from '@app/pages/AutorapLanding/header/MainNavigation';
import MobileDownloadButton from '@app/pages/AutorapLanding/header/MobileDownloadButton';
import MobileNavigation from '@app/pages/AutorapLanding/header/MobileNavigation';
import ContentContainer from '@app/pages/AutorapLanding/shared/ContentContainer';
import { theme } from '@app/config';

interface StickyHeaderProps {
  isSticked: boolean;
}

const StickyHeaderWrapper = styled.div<StickyHeaderProps>`
  position: ${(props) => (props.isSticked ? 'fixed' : '')};
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: ${autorapLayoutSizes.headerHeight};
  background-color: ${COLORS.black};
  z-index: 9;
  transition: height 0.5s;

  ${theme.breakpoints.mobile} {
    height: 60px;
  }
`;

const DesktopContainer = styled(ContentContainer)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  ${theme.breakpoints.mobile} {
    display: none;
  }

  &:after {
    content: '';
    position: absolute;
    right: 30px;
    bottom: 0;
    left: 30px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.18);
  }
`;

const MobileContainer = styled(ContentContainer)`
  display: none;
  position: relative;

  ${theme.breakpoints.mobile} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
`;

const StickyHeader: React.FC<StickyHeaderProps> = ({ isSticked }) => {
  return (
    <StickyHeaderWrapper isSticked={isSticked}>
      <DesktopContainer>
        <LogoAutorap isHeaderSticked={isSticked} />
        <MainNavigation isHeaderSticked={isSticked} />
      </DesktopContainer>
      <MobileContainer>
        <MobileNavigation />
        {isSticked && <MobileDownloadButton />}
      </MobileContainer>
    </StickyHeaderWrapper>
  );
};

export default StickyHeader;
