/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import { theme, pages } from '@app/config';

const SocialNetworkButton = styled.a.attrs((props: any) => ({
  'aria-label': props.type,
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  display: flex;
  align-items: center;
  width: 58px;

  ${theme.breakpoints.mobile} {
    width: 37px;
  }
`;

export const FacebookButton: React.FC = (props: any) => (
  <SocialNetworkButton
    href={pages.autorap.socialNetworks.facebook.url}
    type="Facebook"
    dangerouslySetInnerHTML={{
      __html: pages.autorap.socialNetworks.facebook.icon,
    }}
    {...props}
  />
);
export const TwitterButton: React.FC = (props: any) => (
  <SocialNetworkButton
    href={pages.autorap.socialNetworks.twitter.url}
    type="Twitter"
    dangerouslySetInnerHTML={{
      __html: pages.autorap.socialNetworks.twitter.icon,
    }}
    {...props}
  />
);
export const InstagramButton: React.FC = (props: any) => (
  <SocialNetworkButton
    href={pages.autorap.socialNetworks.instagram.url}
    type="Instagram"
    dangerouslySetInnerHTML={{
      __html: pages.autorap.socialNetworks.instagram.icon,
    }}
    {...props}
  />
);
export const YoutubeButton: React.FC = (props: any) => (
  <SocialNetworkButton
    href={pages.autorap.socialNetworks.youtube.url}
    type="YouTube"
    dangerouslySetInnerHTML={{
      __html: pages.autorap.socialNetworks.youtube.icon,
    }}
    {...props}
  />
);
