/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import { HeaderSmokeMobile, HeaderSmokeMobile2x } from '@app/assets';
import { COLORS, autorapSections } from '@app/constants';
import {
  AppleStoreButton,
  GoogleStoreButton,
} from '@app/pages/AutorapLanding/shared/StoreLinkButtons';
import { assets, theme } from '@app/config';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.mobile} {
    position: relative;
    align-items: center;
    justify-content: center;
    height: 400px;
    margin: -70px 0 -140px;
    background: url('${HeaderSmokeMobile}') no-repeat center top / 100%;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 110px;
      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        ${COLORS.black} 100%
      );
    }

    ${theme.breakpoints.retina} {
      background-image: url('${HeaderSmokeMobile2x}');
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  max-width: 100%;

  ${theme.breakpoints.mobile} {
    flex-direction: column;
  }

  & > *:not(:last-child) {
    margin-right: 35px;
  }
`;

// Visible only on mobile view.
const ArrowsIcon = styled.div`
  display: none;

  ${theme.breakpoints.mobile} {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-top: 18px;
  }
`;

const StoreLinks: React.FC = () => (
  <Container>
    <ButtonsContainer>
      <AppleStoreButton
        sectionId={autorapSections.HEADER}
        data-test-id="header-btn-applestore"
      />
      <GoogleStoreButton
        sectionId={autorapSections.HEADER}
        data-test-id="header-btn-googlestore"
      />
    </ButtonsContainer>
    <ArrowsIcon dangerouslySetInnerHTML={{ __html: assets.arrowsIcon }} />
  </Container>
);

export default StoreLinks;
