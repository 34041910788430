/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';
import { COLORS } from '@app/constants';

const HamburgerIcon = styled.span`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 3px;
  background-color: ${COLORS.white};
  border-radius: 1.5px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 20px;
    height: 3px;
    background-color: ${COLORS.white};
    border-radius: 1.5px;
  }

  &:before {
    top: -7px;
  }

  &:after {
    bottom: -7px;
  }
`;

const XIcon = styled.span`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  &:before,
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    left: 9px;
    width: 3px;
    height: 21px;
    background-color: ${COLORS.white};
    border-radius: 1.5px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

const MobileNavigationItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 42px;
  height: 40px;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  z-index: 9999;
`;

interface ToggleProps {
  isMobileNavigationOpened: boolean;
  onClick: () => void;
}

const MobileNavigationToggle: React.FC<ToggleProps> = ({
  isMobileNavigationOpened,
  onClick,
}) => (
  <MobileNavigationItem
    aria-label="Open Navigation Menu"
    tabIndex={0}
    data-test-id="navbar-toggle"
    onClick={onClick}
  >
    {isMobileNavigationOpened ? <XIcon /> : <HamburgerIcon />}
  </MobileNavigationItem>
);

export default MobileNavigationToggle;
