/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import { HeaderSmoke, HeaderSmoke2x } from '@app/assets';
import { COLORS, autorapLayoutSizes } from '@app/constants';
import MobileLogo from '@app/pages/AutorapLanding/header/MobileLogo';
import StoreLinks from '@app/pages/AutorapLanding/header/StoreLinks';
import ContentContainer from '@app/pages/AutorapLanding/shared/ContentContainer';
import { isLaptop, isMobile } from '@app/utils/utils';
import { theme } from '@app/config';

interface ContainerProps {
  isSticked: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${(props) =>
    props.isSticked ? (isMobile ? 510 : isLaptop ? 650 : 730) : 550}px;
  padding-top: ${(props) =>
    props.isSticked
      ? isMobile
        ? autorapLayoutSizes.mobileHeaderHeight
        : autorapLayoutSizes.headerHeight
      : 0}px;
  color: ${COLORS.white};
  background: url('${HeaderSmoke}') no-repeat calc(50% + 385px) bottom / 800px;

  ${theme.breakpoints.retina} {
    background-image: url('${HeaderSmoke2x}');
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 300px;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      ${COLORS.black} 100%
    );
  }

  & > * {
    z-index: 2;
  }

  ${theme.breakpoints.desktop} {
    background-position-x: calc(50% + 320px);
    background-size: 650px;
  }

  ${theme.breakpoints.tablet} {
    background-position-x: calc(50% + 200px);
    background-size: 700px;
  }

  ${theme.breakpoints.mobile} {
    height: calc(100vh - 80px);
    text-align: center;
    background: none;
  }

  ${theme.breakpoints.xsmobile} {
    min-height: calc(
      100vh -
        ${(props) =>
          props.isSticked ? autorapLayoutSizes.mobileHeaderHeight : '0'}px
    );
    height: auto;
  }
`;

const SloganText = styled.div`
  font-size: 48px;
  font-weight: 400;

  ${theme.breakpoints.desktop} {
    font-size: 43px;
  }

  ${theme.breakpoints.tablet} {
    font-size: 37px;
  }

  ${theme.breakpoints.mobile} {
    max-width: 330px;
    margin: 0 auto;
    font-size: 29px;
    font-weight: 700;
    line-height: 35px;
  }

  ${theme.breakpoints.xsmobile} {
    font-size: 27px;
    line-height: 33px;
  }
`;

const SloganDescription = styled.div`
  max-width: 690px;
  margin: 50px 0 60px;
  font-size: 30px;
  font-weight: 500;
  opacity: 0.8;

  ${theme.breakpoints.desktop} {
    max-width: 630px;
    margin-top: 30px;
    font-size: 27px;
  }

  ${theme.breakpoints.tablet} {
    font-size: 25px;
  }

  ${theme.breakpoints.mobile} {
    position: relative;
    max-width: 300px;
    margin: 25px auto 0;
    font-size: 18px;
    letter-spacing: -0.3px;
    z-index: 3;
  }

  ${theme.breakpoints.xsmobile} {
    font-size: 17px;
  }
`;

interface StaticHeaderProps {
  isSticked: boolean;
}

const StaticHeader: React.FC<StaticHeaderProps> = ({ isSticked }) => (
  <Container isSticked={isSticked}>
    <ContentContainer>
      <MobileLogo />
      <SloganText>Turn Your Speech into Rap</SloganText>
      <SloganDescription>
        Record your next rap song in a matter of seconds and get props from your
        friends and community.
      </SloganDescription>
      <StoreLinks />
    </ContentContainer>
  </Container>
);

export default StaticHeader;
