/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { theme } from '@app/config';
import { HomeAppsAutorapSvg } from '@app/assets';

const Icon = () => <SVG src={HomeAppsAutorapSvg} uniquifyIDs={true} />;

const MobileLogoContainer = styled.div`
  display: none;

  ${theme.breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
  }
`;

const LogoSVGContainer = styled.div`
  width: 124px;
  height: 124px;
  border-radius: 16px;
  overflow: hidden;

  ${theme.breakpoints.xsmobile} {
    width: 100px;
    height: 100px;
  }

  & svg {
    width: 100%;
    height: auto;
  }
`;

const LogoText = styled.div`
  margin-top: 15px;
  font-size: 18px;
  font-weight: 300;
`;

const MobileLogo: React.FC = () => {
  return (
    <MobileLogoContainer>
      <LogoSVGContainer>
        <Icon />
      </LogoSVGContainer>
      <LogoText>AutoRap</LogoText>
    </MobileLogoContainer>
  );
};

export default MobileLogo;
