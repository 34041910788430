/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import { COLORS } from '@app/constants';
import { theme, pages } from '@app/config';

const NavigationContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 75px;

  & > *:not(:last-child) {
    margin-right: 25px;
  }

  ${theme.breakpoints.mobile} {
    margin-top: 40px;
  }
`;

const NavigationLink = styled.a.attrs(() => ({
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  font-size: 18px;
  color: ${COLORS.white};
  text-decoration: none;

  ${theme.breakpoints.mobile} {
    font-size: 12px;
    opacity: 0.8;
  }
`;

const navigationItems = pages.autorap.bottomNavigation.map(
  (navigationItem, index) => (
    <NavigationLink key={index} href={navigationItem.url}>
      {navigationItem.label}
    </NavigationLink>
  )
);

const BottomNavigation: React.FC = () => (
  <NavigationContainer>{navigationItems}</NavigationContainer>
);

export default BottomNavigation;
