/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import styled from 'styled-components';

import { theme } from '@app/config';

const ContentContainer = styled.div`
  max-width: ${theme.sizes.maxContentWidth};
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;

  ${theme.breakpoints.mobile} {
    padding: 0;
  }
`;

export default ContentContainer;
