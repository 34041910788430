/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import Cookies from 'cookies-js';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  COLORS,
  COOKIES_BANNER_COOKIE_NAME,
  COOKIES_BANNER_EXPIRATION,
} from '@app/constants';
import { theme } from '@app/config';

const CookiesBannerContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 30px 64px 30px 50px;
  color: ${COLORS.white};
  font-size: 16px;
  background-color: ${COLORS.black};
  z-index: 100201;
  box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.1);

  ${theme.breakpoints.tablet} {
    padding-left: 25px;
  }

  ${theme.breakpoints.mobile} {
    top: 0;
    bottom: auto;
    padding: 20px 64px 20px 25px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  }

  a {
    color: ${COLORS.white};
    font-weight: bold;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  right: 20px;
  top: 18px;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 19px;
    height: 2px;
    background-color: ${COLORS.white};
    border-radius: 3px;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 19px;
    height: 2px;
    background-color: ${COLORS.white};
    border-radius: 3px;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

const CookiesBanner: React.FC = () => {
  const [isVisible, setIsVisible] = useState(
    !Cookies.get(COOKIES_BANNER_COOKIE_NAME)
  );
  const isInIframe = window !== window.parent;

  useEffect(() => {
    Cookies.set(COOKIES_BANNER_COOKIE_NAME, 'true', {
      expires: COOKIES_BANNER_EXPIRATION,
    });
  }, []);

  const onCloseButtonClick = (): void => {
    setIsVisible(false);
  };

  if (!isVisible || isInIframe) return null;

  return (
    <CookiesBannerContainer>
      <span>
        We use cookies for marketing and to give you the best experience. By
        navigating the site, you consent to our use of cookies. For details and
        controls, see our{' '}
        <a href="/cookiepolicy">
          <span>Cookie Policy</span>
        </a>
        .
      </span>
      <CloseButton
        data-test-id="cookie-banner-close-btn"
        onClick={onCloseButtonClick}
      />
    </CookiesBannerContainer>
  );
};

export default CookiesBanner;
