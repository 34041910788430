/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import { COLORS, autorapSections } from '@app/constants';
import ScreensPreview from '@app/pages/AutorapLanding/about/ScreensPreview';
import ContentContainer from '@app/pages/AutorapLanding/shared/ContentContainer';
import SectionHeader from '@app/pages/AutorapLanding/shared/SectionHeader';
import { theme, pages } from '@app/config';

const AboutContainer = styled.section`
  padding: 100px 0 90px;
  background-color: #eaeaea;
  text-align: center;

  ${theme.breakpoints.mobile} {
    position: relative;
    padding: 80px 0 0;
    background-color: inherit;
    z-index: 2;
  }

  ${theme.breakpoints.xsmobile} {
    padding-top: 100px;
  }
`;

const AboutHeader = styled(SectionHeader)`
  color: ${COLORS.black};

  ${theme.breakpoints.mobile} {
    color: ${COLORS.white};
  }
`;

const AboutDescription = styled.div`
  max-width: 640px;
  margin: 35px auto 0;
  font-size: 23px;
  line-height: 28px;
  color: ${COLORS.black};
  opacity: 0.4;

  ${theme.breakpoints.desktop} {
    font-size: 21px;
    line-height: 25px;
  }

  ${theme.breakpoints.mobile} {
    max-width: 300px;
    margin-top: 25px;
    font-size: 18px;
    line-height: 21px;
    color: ${COLORS.white};
    opacity: 0.8;
  }
`;

const AboutSection: React.FC = () => (
  <AboutContainer id={autorapSections.ABOUT}>
    <ContentContainer>
      <AboutHeader>{pages.autorap.messages.aboutHeader}</AboutHeader>
      <AboutDescription>
        {pages.autorap.messages.aboutDescription}
      </AboutDescription>
      <ScreensPreview />
    </ContentContainer>
  </AboutContainer>
);

export default AboutSection;
