/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import { FooterSmokeMobile, FooterSmokeMobile2x } from '@app/assets';
import { COLORS, autorapSections } from '@app/constants';
import BottomNavigation from '@app/pages/AutorapLanding/footer/BottomNavigation';
import SocialNetworks from '@app/pages/AutorapLanding/footer/SocialNetworks';
import StoreLinks from '@app/pages/AutorapLanding/footer/StoreLinks';
import ContentContainer from '@app/pages/AutorapLanding/shared/ContentContainer';
import { theme, pages } from '@app/config';

const FooterContainer = styled.footer`
  padding: 80px 0 115px;
  background-color: ${COLORS.black};
  color: ${COLORS.white};

  ${theme.breakpoints.mobile} {
    padding: 70px 0 85px;
  }
`;

const FooterContentContainer = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const FooterHeader = styled.div`
  font-size: 46px;
  line-height: 50px;

  ${theme.breakpoints.desktop} {
    font-size: 42px;
    line-height: 46px;
  }

  ${theme.breakpoints.mobile} {
    font-size: 24px;
    line-height: 29px;
  }
`;

const FooterNote = styled.div`
  margin-top: 30px;
  font-size: 22px;
  opacity: 0.8;

  ${theme.breakpoints.desktop} {
    font-size: 21px;
  }

  ${theme.breakpoints.mobile} {
    max-width: 300px;
    margin: 15px auto 0;
    font-size: 18px;
    line-height: 28px;
  }
`;

const SmokeBackground = styled.div`
  ${theme.breakpoints.mobile} {
    position: relative;
    width: 100%;
    height: 190px;
    margin-top: 10px;
    background: url('${FooterSmokeMobile}') no-repeat top center / cover;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 80px;
      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        ${COLORS.black} 100%
      );
    }

    ${theme.breakpoints.retina} {
      background-image: url('${FooterSmokeMobile2x}');
    }
  }
`;

const FooterSection: React.FC = () => (
  <FooterContainer id={autorapSections.CONTACT}>
    <FooterContentContainer>
      <FooterHeader>{pages.autorap.messages.footerHeader}</FooterHeader>
      <FooterNote>{pages.autorap.messages.footerNote}</FooterNote>
      <StoreLinks />
      <SmokeBackground />
      <SocialNetworks />
      <BottomNavigation />
    </FooterContentContainer>
  </FooterContainer>
);

export default FooterSection;
