/*
 * Copyright 2010-2024 (c) Smule Inc. All Rights Reserved.
 * This code is proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import styled from 'styled-components';

import { theme } from '@app/config';

const FeatureItemContainer = styled.div`
  max-width: 300px;
`;

const FeatureImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
`;

const FeatureHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 42px;
  margin-top: 30px;
  font-size: 18px;
  font-weight: 800;
  line-height: 21px;
  text-transform: uppercase;

  ${theme.breakpoints.mobile} {
    align-items: center;
  }
`;

const FeatureDescription = styled.div`
  margin-top: 15px;
  font-size: 16px;
  line-height: 28px;
  opacity: 0.8;

  ${theme.breakpoints.mobile} {
    margin-top: 25px;
    font-size: 16px;
    line-height: 28px;
  }
`;

interface OwnProps {
  image: string;
  header: string;
  description: string;
}

const FeatureItem: React.FC<OwnProps> = (props) => (
  <FeatureItemContainer>
    <FeatureImage dangerouslySetInnerHTML={{ __html: props.image }} />
    <FeatureHeader>{props.header}</FeatureHeader>
    <FeatureDescription>{props.description}</FeatureDescription>
  </FeatureItemContainer>
);

export default FeatureItem;
